import React from "react"
import { Trans } from "react-i18next"
import { setCookie } from "../cookies"

export const GDPRnotification = ({ setNotification }) => {
  return (
    <div
      style={{ position: "fixed", bottom: 0, zIndex: 5 }}
      className="text-light bg-dark w-100 p-5 fw-light small border-top border-light shadow-lg"
    >
      <p>
        <Trans>
          This website stores cookies on your computer. These cookies are used
          to collect information about how you interact with our website and
          allow us to remember you. We use this information in order to improve
          and customize your browsing experience and for analytics and metrics
          about our visitors both on this website and other media. To find out
          more about the cookies we use, see our Privacy Policy.
        </Trans>
      </p>
      <p>
        <Trans>
          If you decline, your information won’t be tracked when you visit this
          website. A single cookie will be used in your browser to remember your
          preference not to be tracked.
        </Trans>
      </p>

      <div className="d-flex gap-3 justify-content-end">
        <button
          className="btn btn-outline-warning"
          onClick={() => setNotification(false)}
        >
          <Trans>Decline</Trans>
        </button>
        <button
          className="btn btn-warning"
          onClick={() => {
            setCookie("gdpr_notification", "true", 30)
            setNotification(false)
          }}
        >
          <Trans>Accept</Trans>
        </button>
      </div>
    </div>
  )
}
