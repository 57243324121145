import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Trans } from "gatsby-plugin-react-i18next"
import {
  BsFacebook,
  BsLinkedin,
  BsTelephoneFill,
  BsInstagram,
} from "react-icons/bs"
import { FaMapMarkerAlt } from "react-icons/fa"

const Footer = () => {
  const date = new Date()
  const year = date.getFullYear()

  return (
    <footer className="bg-light border-top border-dark shadow">
      <div className="container pt-5 px-5 gap-5">
        <div className="row d-flex">
          <div className="col-6 col-md">
            <h5>
              <Trans>Site</Trans>
            </h5>
            <ul className="list-unstyled text-small">
              <li>
                <Link className="text-dark" to="/">
                  <Trans>Home</Trans>
                </Link>
              </li>
              <li>
                <Link className="text-dark" to="/applications">
                  <Trans>Applications</Trans>
                </Link>
              </li>
              <li className="text-muted">
                <Trans>Resources</Trans>
                <ul>
                  <li>
                    <Link className="text-dark" to="/how">
                      <Trans>How</Trans>
                    </Link>
                  </li>
                  <li>
                    <Link className="text-dark" to="/surveysetup">
                      <Trans>Setup Survey</Trans>
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link className="text-dark" to="/plans">
                  <Trans>Plans & Pricing</Trans>
                </Link>
              </li>
              <li>
                <Link className="text-dark" to="/contact">
                  <Trans>Contact</Trans>
                </Link>
              </li>
            </ul>
          </div>

          <div className="col-6 col-md d-flex flex-column align-items-center">
            <div className="">
              <h5>
                <Trans>Find us</Trans>
              </h5>
              <ul className="list-unstyled text-small">
                <li>
                  <Link
                    className="text-dark py-1"
                    to="https://www.facebook.com/Uhappy-109230168442155/"
                  >
                    <BsFacebook className="me-2" />
                    Facebook
                  </Link>
                </li>
                <li>
                  <Link className="text-dark  py-1" to="https://linkedin.com">
                    <BsLinkedin className="me-2" />
                    LinkedIn
                  </Link>
                </li>

                <li>
                  <Link
                    className="text-dark  py-1"
                    to="https://instagram.com/uhappy.gr"
                  >
                    <BsInstagram className="me-2" />
                    Instagram
                  </Link>
                </li>

                <li>
                  <Link className="text-dark py-1" to="tel:2165005060">
                    <BsTelephoneFill className="me-2" />
                    216 500 5060
                  </Link>
                </li>

                <li>
                  <Link
                    className="text-dark py-1 text-center"
                    to="https://goo.gl/maps/iTrCoVFdwjWPUtY99"
                  >
                    <FaMapMarkerAlt className="me-2" />
                    <Trans>Zaloggou 1-5</Trans>
                    <br />
                    <Trans>111 46, Galatsi</Trans>
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-12 col-md footer-logo mt-sm-0 mt-5">
            <Link to="/" className="lnlnk-none">
              <StaticImage src="../images/uhappy.svg" alt="Logo" width={175} />
            </Link>
            <small className="d-block mb-3 text-muted pt-4">
              <div>
                {year}{" "}
                <Link to="/" className="text-dark">
                  uhappy?
                </Link>
                <small> ©</small>
              </div>
              <div>
                {<Trans>by</Trans>}{" "}
                <a className="text-dark" href="https://day-one.gr">
                  {process.env.GATSBY_COMPANY}
                </a>
                <small> ©</small>
              </div>
            </small>
          </div>
        </div>

        <div className="d-flex justify-content-center pt-4">
          <div
            className="d-flex gap-4 border-1 border-secondary bg-white
          py-2 px-5 shadow rounded-top border-top border-start border-end"
          >
            <Link className="text-dark small text-center" to="/">
              <Trans>Privacy</Trans>
            </Link>
            <Link className="text-dark small text-center" to="/terms">
              <Trans>Terms</Trans>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
