import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import Footer from "./footer"
import { GDPRnotification } from "./gdprNotification"
import { checkCookie, getCookie } from "../cookies"

const Layout = ({ children, hero }) => {
  const [notification, setNotification] = useState(true)

  useEffect(() => {
    checkCookie("gdpr_notification")

    getCookie("gdpr_notification") === ""
      ? setNotification(true)
      : setNotification(false)
  }, [])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      {hero}
      <main>{children}</main>
      {notification && <GDPRnotification setNotification={setNotification} />}
      <Footer />
    </>
  )
}

export default Layout
