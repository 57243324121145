import * as React from "react"
import { Link } from "gatsby"
// import {ReactComponent  as Logo} from "../images/uhappyMono.svg"
import { StaticImage } from "gatsby-plugin-image"
import { TiThMenu } from "react-icons/ti"
import Dropdown from "./dropdown"
import { LanguageSelect } from "./languages"
import { Trans } from "gatsby-plugin-react-i18next"

const Header = () => (
  <nav className=" sticky-top navbar navbar-expand-lg navbar-dark bg-dark border-bottom border-light shadow">
    <div
      className="container-fluid"
      style={{
        maxWidth: 1200,
      }}
    >
      <Link to="/" className="navbar-brand">
        <StaticImage
          src="../images/uhappy.svg"
          alt="Logo"
          className="m-4"
          height={40}
        />
      </Link>
      <button
        className="navbar-toggler border border-warning px-3 py-2"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNavAltMarkup"
        aria-controls="navbarNavAltMarkup"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <TiThMenu className="text-warning" />
      </button>
      <div
        className="collapse navbar-collapse justify-content-end"
        id="navbarNavAltMarkup"
      >
        <div className="navbar-nav justify-content-evenly w-100 align-items-center">
          <Link
            to="/applications"
            className="nav-link text-light text-center p-3 lnlnk"
          >
            <Trans>Applications</Trans>
          </Link>
          <Dropdown />

          <Link
            to="/plans"
            className="nav-link text-light text-center p-3 lnlnk"
          >
            <Trans>Plans & Pricing</Trans>
          </Link>

          <Link
            to="/contact"
            className="nav-link text-light text-center p-3 lnlnk"
          >
            <Trans>Contact</Trans>
          </Link>
        </div>

        <div
          className="d-flex justify-content-center p-4 p-sm-4"
          style={{ columnGap: "1rem" }}
        >
          <a
            className="btn btn-outline-warning text-nowrap"
            href="https://app.uhappy.gr/login"
          >
            <Trans>Log In</Trans>
          </a>
          <a
            className="btn btn-warning text-nowrap"
            href="https://app.uhappy.gr/signup"
          >
            <Trans>Sign Up</Trans>
          </a>
          <LanguageSelect />
        </div>
      </div>
    </div>
  </nav>
)

export default Header
