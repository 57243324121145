import React from "react"
import { Link } from "gatsby"
import { Trans } from "gatsby-plugin-react-i18next"
import ClickAwayListener from "react-click-away-listener"

export default function Dropdown() {
  const [show, setShow] = React.useState(false)

  return (
    <ClickAwayListener onClickAway={() => setShow(false)}>
      <div
        className="dropdown text-light nav-link text-light
        text-center p-3 d-flex justify-content-center lnlnk"
        onClick={() => setShow(!show)}
      >
        <Trans>Resources</Trans>
        <ul
          className={`dropdown_menu text-dark position-absolute p-4 
          bg-warning border-2 list-unstyled shadow border border-light  
          ${show && " show"}`}
          style={{ borderRadius: "1rem" }}
        >
          <Link to="/how" className="nav-link text-dark">
            <li className="py-2 px-4 rounded">
              <Trans>How</Trans>
            </li>
          </Link>
          <Link to="/surveysetup" className="nav-link text-dark">
            <li className="py-2 px-4 rounded">
              <Trans>Setup Survey</Trans>
            </li>
          </Link>
        </ul>
      </div>
    </ClickAwayListener>
  )
}
