import React from "react"
import Flags from "country-flag-icons/react/3x2"
import { Link, useI18next } from "gatsby-plugin-react-i18next"

export const LanguageSelect = ({ onDarkBackground = true }) => {
  const { language, originalPath } = useI18next()

  return (
    <div className="dropdown d-flex align-items-center">
      <div
        style={{
          color: `${onDarkBackground ? "white" : "black"}`,
          backgroundColor: "transparent",
        }}
        type="button"
        id="dropdownMenuButton1"
        data-bs-toggle="dropdown"
        className="removeFocus border-0 dropdown-toggle"
      >
        {language === "en" ? (
          <>
            <Flags.GB
              title="English"
              style={{ width: 25, height: 25, borderRadius: 25 }}
            />
          </>
        ) : (
          <>
            <Flags.GR
              title="Ελληνικά"
              style={{ width: 25, height: 25, borderRadius: 25 }}
            />
          </>
        )}
      </div>
      <ul
        className="dropdown-menu shadow bg-dark
      border border-1 border-light "
      >
        <Link
          to={originalPath}
          language={"en"}
          className="text-light d-flex align-items-center dropdown-item"
        >
          <Flags.GB
            title="United States"
            className="me-3"
            style={{ width: 25, height: 25, borderRadius: 25 }}
          />
          English
        </Link>
        <Link
          to={originalPath}
          language={"gr"}
          className="text-light d-flex align-items-center dropdown-item"
        >
          <Flags.GR
            title="Greece"
            className="me-3"
            style={{ width: 25, height: 25, borderRadius: 25 }}
          />
          Ελληνικά
        </Link>
      </ul>
    </div>
  )
}
